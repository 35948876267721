<template>
  <div class="log" style="">
    <button
      v-if="type == 'add'"
      type="button"
      color="blue"
      outline
      class="advance-ass-btn"
      @click.stop="dialogPay = false"
      @click="check()"
    >
      Pay Now
    </button>
    <!-- v-permissions="'edit-paid-advance'" -->
    <v-btn
      v-if="type == 'edit'"
      style="width: 30px; height: 30px; cursor: pointer"
      outline
      small
      fab
      color="orange"
      @click.stop="dialogPay = false"
      @click="check()"
    >
      <v-icon>edit</v-icon>
    </v-btn>

    <v-dialog v-model="dialogPay" persistent max-width="700">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 3px;
              padding-top: 3px;
            "
          >
            <span class="headline"
              >{{ type == "edit" ? "Edit" : "Pay" }} Advance
            </span>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>

          <v-card-text style="overflow: auto; height: 550px">
            <v-layout>
              <v-flex xs12 md12 pb-1>
                <div class="heading">
                  Are you sure want to pay to the driver?
                </div></v-flex
              ></v-layout
            >
            <v-layout align-center>
              <v-flex xs6 md6>
                <div class="sub-heading muted">
                  Driver’s Payment credit period :
                  {{
                    driverCreditDuration !== 0 &&
                    driverCreditDuration !== undefined
                      ? driverCreditDuration
                      : "N.A"
                  }}
                  {{
                    driverCreditDuration !== 0 &&
                    driverCreditDuration !== undefined
                      ? "days"
                      : ""
                  }}
                  <span style="color: black" class="heading"> </span>
                </div>
              </v-flex>
              <v-flex xs6 md6>
                <div class="sub-heading muted">
                  Advance Issued :
                  <span style="color: black" class="heading">
                    {{
                      assignmentData.advanceAmount
                        ? assignmentData.advanceAmount
                        : "N.A"
                    }}
                    {{ assignmentData.advanceAmount ? currency : "" }}</span
                  >
                </div>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6 md6>
                <div class="sub-heading muted">
                  Amount Paid till date :
                  <span style="color: black" class="heading">
                    {{
                      this.$props.actualamoutPaid
                        ? this.$props.actualamoutPaid
                        : "N.A"
                    }}
                    {{ this.$props.actualamoutPaid ? currency : "" }}</span
                  >
                </div>
              </v-flex>
            </v-layout>

            <v-layout class="pt-0 pb-3">
              <v-flex class="span__padding">
                <span class="heading__span">Driver Payment Details</span>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs8>
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="paymentMode"
                  row
                  :mandatory="true"
                  :rules="[rules.required]"
                  readonly
                >
                  <div>
                    <div class>
                      <v-layout>
                        <v-radio
                          label="Cash"
                          value="Cash"
                          color="black"
                          class="col-md-6"
                        ></v-radio>

                        <v-radio
                          label="Bank Transfer"
                          value="Bank Transfer"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout
              class="pb-0 title-layout-size"
              v-if="paymentMode === 'Cash'"
            >
              <v-flex md6 pr-5 class="payadvance">
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="accountHolderName"
                      label="Account Holder Name"
                      placeholder="Enter account holder name"
                      :value="accountHolderName"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="ifscCode"
                      label="IBAN Number"
                      placeholder="Enter IBAN Number"
                      maxlength="25"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md6 class="payadvance">
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="swiftNumber"
                      label="Swift Number "
                      placeholder="Enter Swift Number"
                      :value="swiftNumber"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="accountNumber"
                      label="Account Number"
                      placeholder=" Enter Account Number"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout class="pb-0 title-layout-size" v-else>
              <v-flex md6 pr-5 class="payadvance">
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="accountHolderName"
                      label="Account Holder Name"
                      placeholder="Enter account holder name"
                      :value="accountHolderName"
                      :rules="[rules.noWhiteSpace, rules.required]"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="ifscCode"
                      label="IBAN Number"
                      placeholder="Enter IBAN Number"
                      :rules="[rules.noWhiteSpace, rules.required]"
                      maxlength="25"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md6 class="payadvance">
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="swiftNumber"
                      label="Swift Number"
                      placeholder="Enter Swift Number"
                      :value="swiftNumber"
                      :rules="[rules.noWhiteSpace, rules.required]"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-text-field
                      v-model="accountNumber"
                      label="Account Number"
                      placeholder=" Enter Account Number"
                      :rules="[rules.noWhiteSpace, rules.required]"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout class="pt-0 pb-3">
              <v-flex class="span__padding">
                <span class="heading__span"> Payment Details</span>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6>
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="debtorPaymentMode"
                  row
                  :mandatory="true"
                  :rules="[rules.required]"
                  @change="getBankName()"
                >
                  <div>
                    <div class>
                      <v-layout>
                        <v-radio
                          label="Cash"
                          value="Cash"
                          color="black"
                          class="col-md-6"
                        ></v-radio>

                        <v-radio
                          label="Bank Transfer"
                          value="Bank Transfer"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs6 pr-5 pt-1>
                <v-layout>
                  <v-flex
                    md12
                    class="payadvance"
                    v-if="debtorPaymentMode === 'Cash'"
                  >
                    <v-layout>
                      <v-flex
                        md12
                        v-if="currency === 'AED' || currency === 'SAR'"
                      >
                        <v-autocomplete
                          label="Select Cash"
                          placeholder="Select Cash"
                          :items="banks"
                          class="pt-0 currencyTitle"
                          v-model="bankName"
                          :disabled="
                            !this.$props.invoiceCity ||
                            !this.$props.invoiceCompany
                          "
                          item-text="text"
                          item-value="value"
                        />
                      </v-flex>
                      <v-flex md12 v-else>
                        <v-text-field
                          v-model="bankName"
                          label="Select Cash"
                          class="pt-0 currencyTitle"
                          placeholder="Select Cash "
                          :value="bankName"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex md12 class="payadvance" v-else>
                    <v-layout>
                      <v-flex
                        md12
                        v-if="currency === 'AED' || currency === 'SAR'"
                      >
                        <v-autocomplete
                          label="Choose Bank Detail"
                          placeholder="Choose Bank Detail"
                          :items="banks"
                          class="pt-0 currencyTitle"
                          v-model="bankName"
                          :disabled="
                            !this.$props.invoiceCity ||
                            !this.$props.invoiceCompany
                          "
                          :rules="[rules.required]"
                          item-value="value"
                          item-text="text"
                          :value="bankName"
                        />
                      </v-flex>
                      <v-flex md12 v-else>
                        <v-text-field
                          v-model="bankName"
                          label="Bank Name"
                          class="pt-0 currencyTitle"
                          placeholder="Bank Name"
                          :value="bankName"
                          :rules="[rules.noWhiteSpace, rules.required]"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 pt-0 class="payadvance">
                <v-text-field
                  style="
                    padding-top: 0px !important;
                    margin-top: 0px !important;
                  "
                  :suffix="assignmentData.paymentCurrency"
                  label="Advance To Be Paid"
                  placeholder="Enter Amount"
                  v-model="advanceAmount"
                  :value="advanceAmount"
                  :rules="[rules.required, rules.number]"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6 pr-5>
                <span class="muted"></span>
                <v-menu
                  ref="FilterDateBool"
                  lazy
                  v-model="FilterDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="startDate"
                >
                  <v-text-field
                    slot="activator"
                    :label="'Select Date '"
                    placeholder="Select Date"
                    v-model="startDate"
                    :rules="[rules.required]"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="startDate"
                    :max="dateToday"
                    class="minHeight"
                    @change="$refs.FilterDateBool.save(startDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 style="position: relative; padding-top: 0px">
                <label
                  :class="
                    actualModeError
                      ? 'light_red label__position__acc muted caption'
                      : 'label__position__acc muted caption'
                  "
                  style="padding-left: 33px"
                  >Select time</label
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="actualModeError ? 'light_red_fill test1' : 'test1'"
                  height="23"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                </svg>
                <div>
                  <vue-timepicker
                    class="fonttime fonttime2 pt-0 currencyTitle"
                    style="text-align: center"
                    :minute-interval="30"
                    v-model="pickupTime"
                    input-width="22em"
                    label="Select Time"
                    :placeholder="'Select Time'"
                    close-on-complete
                  ></vue-timepicker>
                  <span
                    v-if="actualModeError"
                    style="
                      color: #ff5252 !important;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                      font-size: 12px;
                      padding-left: 32px;
                    "
                    >{{ errorMessage }}</span
                  >
                </div>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12 md12>
                <v-text-field
                  required
                  label="Description"
                  v-model="description"
                  :rules="[rules.noWhiteSpace, rules.required]"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="pt-100" pt-2>
                <div class="upload-btn-wrapper">
                  <button class="btn widthbtn" v-if="document.length < 30">
                    Upload Documents
                  </button>
                  <input
                    ref="fileUpload"
                    id="fileUpload"
                    type="file"
                    multiple
                    accept=".pdf, .jpeg, .jpg, .png"
                    @change="uploadDocument"
                    title=""
                  />
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              ><v-flex xs6>
                <ul style="padding: 0px !important">
                  <li
                    style="position: relative"
                    :key="i"
                    v-for="(doc, i) in document"
                    class="list"
                    ref="documentList"
                  >
                    <a
                      :href="doc"
                      class="document-link"
                      v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                      target="_blank"
                    >
                      <img src="../../../assets/pfd.png" />
                      <button
                        type="button"
                        v-show="document"
                        @click="removeImage(i)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                    </a>
                    <a :href="doc" class="document-link" target="_blank" v-else>
                      <img
                        src="../../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                      <button
                        type="button"
                        v-show="document"
                        @click="removeImage(i)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                    </a>
                  </li>
                </ul>
              </v-flex></v-layout
            >
          </v-card-text>
          <div
            v-if="error"
            style="text-align: center; padding-top: 4px"
            class="red--text"
          >
            {{ error }}
          </div>
          <v-card-actions>
            <v-layout>
              <v-spacer />
              <v-btn flat @click.native="close">Cancel</v-btn>
              <v-btn
                :loading="processing"
                :disabled="processing"
                color="orange darken-2"
                class="white--text"
                @click.native="checkAdvance()"
                >Pay</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
        <success-dialog
          :content="x.message"
          :heading="x.heading"
          :show="x.success"
          :onclose="closeMessage"
        />
      </v-form>
      <v-dialog v-model="confirmationDialog" max-width="23%" persistent>
        <v-card>
          <v-spacer class="spacerclass">
            <v-card-text style="padding-top: 0px !important">
              <v-layout class="pt-4" style="text-align: center">
                <v-flex pr-2 pb-3>
                  <v-icon x-large color="orange">info</v-icon></v-flex
                >
              </v-layout>
              <v-layout>
                <v-flex pb-3 style="text-align: center">
                  Advance amount exceeds the driver price.</v-flex
                >
              </v-layout>
              <v-layout>
                <v-flex style="text-align: center">
                  <v-btn
                    color="white darken-1"
                    style="
                      background: orange !important;
                      min-width: 66px !important;
                    "
                    flat
                    @click.prevent="confirm()"
                    :loading="processing"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>

        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { StorageKeys } from "../../../constants/constants";
import { addProofOfDelivery, uploadImage } from "@/constants/api-urls.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import SuccessDialog from "@/components/Common/SuccessDialog";
import moment from "moment";
export default {
  props: {
    assignmentData: Object,
    invoiceCity: String,
    invoiceCompany: String,
    assignId: Number,
    currency: String,
    driverCreditDuration: Number,
    totalAdvancePaidArray: Number,
    totaladvancePaid: Number,
    driverPrice: Number,
    additionalCharges: Number,
    assignedToTransporter: Boolean,
    ownerType: String,
    actualamoutPaid: Number,
    type: String,
    editData: Object,
  },
  components: {
    VueTimepicker,
    SuccessDialog,
  },
  data() {
    return {
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      paymentMode: null,
      debtorPaymentMode: "Cash",
      loading: false,
      errorMessage: "This field is required",
      actualModeError: false,

      banks: [],
      bankName: "",
      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      startDate: "",
      pickupTime: {
        HH: "",
        mm: "",
      },
      FilterDateBool: false,
      x: {
        message: "The advance payment has been successfully done",
        heading: "Advance Payment Update",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      valid: true,
      document: [],
      lazy: false,
      description: null,
      paymentMode: "",
      accountHolderName: "",
      swiftNumber: "",
      comments: "",

      accountNumber: "",
      ifscCode: "",
      processing: false,
      operationName: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
      advanceAmount: "",
      dialogPay: false,
      error: null,
    };
  },
  created() {},
  watch: {
    pickupTime(val) {
      if (val.HH || val.mm) {
        this.checkTime();
        this.actualModeError = false;
      }
    },
  },
  methods: {
    currentTime() {
      var currentdate = new Date();
      this.pickupTime = (
        currentdate.getHours() +
        ":" +
        (currentdate.getMinutes() <= 9
          ? "0" + currentdate.getMinutes()
          : currentdate.getMinutes())
      ).toString();
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
      this.$emit("assign-list-refresh");
    },
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;
      console.log(this.pickupTime);
      if (this.type == "edit") {
        var time = this.pickupTime;
      } else {
        var time = this.pickupTime.HH + ":" + this.pickupTime.mm;
      }

      this.actualModeError = false;
      console.log(time);

      if (time != "" && !time.match(re)) {
        this.processing = false;
        this.actualModeError = true;
        this.errorMessage = "Please select the valid time";
        // time.focus();
        return false;
      }
    },
    async check() {
      this.processing = true;
      console.log(this.editData);
      this.operationName = "pay-advance-for-accounts";
      this.getBankName();
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$emit("close-partial-table");
        this.processing = false;
        this.open();
      } else {
        this.processing = false;
        return;
      }
    },
    getBankName() {
      this.banks = [];

      if (
        (this.$props.invoiceCompany == "2" &&
          this.debtorPaymentMode == "Cash") ||
        (this.$props.invoiceCompany == "4" && this.debtorPaymentMode == "Cash")
      ) {
        this.bankName = "";
        // this.bankName = "Cash";

        this.banks = ["Cash"];
      } else {
        // if (this.$props.invoiceCompany == "1") {
        this.banks = [];
        let obj = {
          Cash: "cash",
          "Bank Transfer": "bankTransfer",
        };
        console.log(obj[this.debtorPaymentMode]);

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          country: this.$props.invoiceCompany,
          modeOfPayment:
            this.$props.invoiceCompany == "2"
              ? "bankTransfer"
              : obj[this.debtorPaymentMode],
        };
        let { apiUrl } = this.constants;
        this.axios
          .post(`${apiUrl}/dmsAdmin/get-ledger-details`, body, config)
          .then(
            (response) => {
              const { data = null } = response.data;
              console.log(response.data);
              data.forEach((element) => {
                console.log(element);
                this.banks.push({
                  text: element.ledgerName,
                  value: element.ledgerName,
                });
              });

              // this.bankName = response.data.data[2].bankName;
              // this.banks = response.data.data;
            },
            (error) => {
              this.error = "Something went wrong. Please try again later!";
              this.loading = false;
            }
          );
      }
      // } else if (
      //   this.$props.invoiceCompany == "2" &&
      //   (this.$props.invoiceCity == "1" || this.$props.invoiceCity == "2")
      // ) {
      //   this.banks = ["Mashreq Bank PSJC", "EMIRATES NBD Bank", "ADCB -  0001"];
      // } else {
      //   return;
      // }
    },
    open() {
      this.accountNumber = this.assignmentData.accountNumber;
      this.accountHolderName = this.assignmentData.accountHolderName;
      this.ifscCode = this.assignmentData.IBANNumber;
      this.swiftNumber = this.assignmentData.swiftNumber;
      this.paymentMode = this.assignmentData.creditorModeOfPayment
        ? this.assignmentData.creditorModeOfPayment
        : "Cash";
      this.debtorPaymentMode = "Cash";
      if (this.editData) {
        this.debtorPaymentMode = this.editData.modeOfPayment;
        this.advanceAmount = this.editData.actualAmountPaid;
        this.startDate = moment(this.editData.actualPaidDate).format(
          "YYYY-MM-DD"
        );
        this.pickupTime = this.editData.actualPaidTime;
        console.log(this.editData.bankName);
        if (this.editData.bankName) {
          this.bankName = this.editData.bankName;
        } else {
          this.bankName = "Cash";
        }
        console.log(this.bankName, "------bankname");

        this.editData.actualamoutPaid;
        this.editData.actualPaidDate;
        this.editData.actualPaidTime;
        this.document = this.editData.document;
        this.getBankName();
      }
      // this.bankName = this.assignmentData.bankName;
      // this.getBankName();
      this.dialogPay = true;
    },
    close() {
      this.dialogPay = false;
      this.loading = false;
      this.actualModeError = false;
      this.accountNumber = this.assignmentData.accountNumber;
      this.accountHolderName = this.assignmentData.accountHolderName;
      this.ifscCode = this.assignmentData.IBANNumber;
      this.swiftNumber = this.assignmentData.swiftNumber;
      this.paymentMode = this.assignmentData.creditorModeOfPayment;
      this.bankName = "";
      this.startDate = "";
      this.debtorPaymentMode = "Cash";
      this.pickupTime = {
        HH: "",
        mm: "",
      };
      this.document = [];
      this.$refs.form.resetValidation();
      (this.description = null), (this.processing = false);
      this.advanceAmount = "";
      this.error = null;
      this.error = "";
    },
    async checkAdvance() {
      this.processing = true;
      this.actualModeError = false;
      this.operationName = "pay-advance-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (this.pickupTime.HH == "" && this.pickupTime.mm == "") {
          this.actualModeError = true;
          this.processing = false;
        }
        if (this.$refs.form.validate()) {
          this.actualModeError = false;
          this.loading = true;

          let totalDriverPrice =
            Number(this.driverPrice) + Number(this.additionalCharges);
          console.log(
            this.driverPrice, //total driver price
            totalDriverPrice, //total driver price
            this.additionalCharges, // additional charge list
            this.assignmentData.advanceAmount, //advance amount issued
            this.advanceAmount, //fill amount via filed
            totalDriverPrice < Number(this.advanceAmount),
            this.totaladvancePaid, // paid till date advance ,
            this.$props.actualamoutPaid // Actual amount paid
          );
          // if( Number(this.advanceAmount) +this.$props.actualamoutPaid   >  this.assignmentData.advanceAmount){
          //   this.loading = false;
          //     this.processing = false;

          //     this.error =
          //       "you pay more than  advance issue amount ";
          //     return false;

          // }
          console.log(
            this.advanceAmount,
            this.totalAdvancePaidArray,
            this.assignmentData.advanceAmount,
            "advance amount , total advance"
          );
          // coment due tio issue
          if (this.actualamoutPaid != undefined && this.type == "add") {
            if (
              Number(this.advanceAmount) + this.actualamoutPaid >
              this.assignmentData.advanceAmount
            ) {
              this.loading = false;
              this.processing = false;

              this.error = `You can not pay more than the pending advance amount ${
                this.assignmentData.advanceAmount - this.actualamoutPaid
              } ${this.currency}`;
              return false;
            }
          } else {
            if (
              Number(this.advanceAmount) > this.assignmentData.advanceAmount
            ) {
              this.loading = false;
              this.processing = false;

              this.error = `You can not pay more than the pending advance amount ${this.assignmentData.advanceAmount} ${this.currency}`;
              return false;
            }
          }

          // if (!this.pickupTime) {
          //   this.actualModeError = true;
          //   this.errorMessage = "Please select time!";
          //   return;
          // }
          if (this.pickupTime) {
            this.checkTime();

            let currentDate = moment(new Date()).format("YYYY-MM-DD, HH:mm");
            let selectedDateTime =
              this.startDate +
              "," +
              this.pickupTime.HH +
              ":" +
              this.pickupTime.mm;
            if (
              new Date(currentDate).getTime() <
              new Date(selectedDateTime).getTime()
            ) {
              this.loading = false;
              this.processing = false;

              this.error =
                "Please select the past time and date before moving ahead!";
              return false;
            }
          }

          if (!this.document.length) {
            this.error = "Please upload proof!";
            this.loading = false;
            this.processing = false;
            return;
          }
          if (this.advanceAmount) {
            if (Number(this.advanceAmount) <= 0) {
              this.error = "Amount must be greater than 0";
              this.loading = false;
              this.processing = false;
              return;
            }
          }

          // if (this.assignmentData.advanceAmount < this.advanceAmount) {
          //   this.loading = false;
          //   this.processing = false;

          //   this.error =
          //     "Advance amount can't be greater than Advance issued amount";
          //   return;
          // } else {
          this.confirm();
          // }

          // if (totalDriverPrice < Number(this.advanceAmount)) {
          //   console.log("---1");
          //   this.loading = false;
          //   this.processing = false;

          //   if (
          //     this.$props.assignedToTransporter ||
          //     this.$props.ownerType == "Transporter"
          //   ) {
          //     this.error =
          //       "Advance amount can't be greater than total amount to be paid to driver";
          //     return;
          //   } else {
          //     this.confirmationDialog = true;
          //     return;
          //   }
          // }
          // if (totalDriverPrice < this.assignmentData.advanceAmount) {
          //   console.log("---2");
          //   this.confirmationDialog = true;

          //   this.loading = false;
          //   this.processing = false;
          //   return;
          // }
          // if (totalDriverPrice < Number(this.totaladvancePaid)) {
          //   console.log("---3");

          //   this.loading = false;
          //   this.processing = false;
          //   if (
          //     this.$props.assignedToTransporter ||
          //     this.$props.ownerType == "Transporter"
          //   ) {
          //     this.error =
          //       "Advance amount can't be greater than total amount to be paid to driver";
          //     return;
          //   } else {
          //     this.confirmationDialog = true;
          //     return;
          //   }
          // }
        } else {
          this.processing = false;
          return;
        }
      } else {
        this.processing = false;

        return;
      }
    },

    confirm() {
      this.processing = true;
      let url;
      if (this.type == "edit") {
        url = `/dmsAdmin/edit-paid-advance/${this.editData._id}`;
      } else {
        url = "/dmsAdmin/paidAdvance";
      }

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        assignmentId: this.assignId.toString(),
        advanceId: this.assignmentData._id,
        totalPayment: this.advanceAmount ? this.advanceAmount.toString() : "",
        modeOfPayment: this.debtorPaymentMode,
        creditorModeOfPayment: this.paymentMode,
        advancePayment: this.assignmentData.advanceAmount.toString(),
        actualDate: this.startDate,
        actualTime:
          this.type == "edit"
            ? this.pickupTime
            : this.pickupTime.HH + ":" + this.pickupTime.mm,
      };
      if (this.bankName) {
        if (
          (this.$props.invoiceCompany == "2" &&
            this.debtorPaymentMode == "Cash") ||
          (this.$props.invoiceCompany == "4" &&
            this.debtorPaymentMode == "Cash")
        ) {
        } else {
          body.bank = this.bankName;
        }
      }
      if (
        (this.bankName && this.$props.invoiceCompany == "1") ||
        (this.bankName && this.$props.invoiceCompany == "2") ||
        (this.bankName && this.$props.invoiceCompany == "4")
      ) {
        if (
          (this.$props.invoiceCompany == "2" &&
            this.debtorPaymentMode == "Cash") ||
          (this.$props.invoiceCompany == "4" &&
            this.debtorPaymentMode == "Cash")
        ) {
        } else {
          body.ledgerName = this.bankName;
        }
      }
      // if (
      //   (this.bankName && this.$props.invoiceCompany == "1") ||
      //   (this.bankName && this.$props.invoiceCompany == "2")
      // ) {
      //   body.ledgerName = this.bankName;
      // }
      if (this.ifscCode) {
        body.IBANNumber = this.ifscCode;
      }
      if (this.swiftNumber) {
        body.swiftNumber = this.swiftNumber;
      }
      if (this.accountHolderName) {
        body.accountHolderName = this.accountHolderName;
      }
      if (this.accountNumber) {
        body.accountNumber = this.accountNumber;
      }

      if (this.description && this.description.trim()) {
        body.desc = this.description.trim();
      }

      if (this.document.length) {
        body.document = this.document;
      }
      if (this.type == "edit") {
        this.axios.put(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.$eventBus.$on("subpartiallist", (value) => {
              console.log("partail list---------socket ");
              this.dialogPay = false;
              this.loading = false;
              this.x.message = response.data.data.message;
              this.processing = false;
              this.x.success = true;
              this.$emit("assign-list-refresh");
              this.close();
            });
          },
          (error) => {
            this.loading = false;
            this.processing = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.dialogPay = false;
            this.loading = false;
            this.x.message = response.data.data.message;
            this.processing = false;
            this.x.success = true;
            this.close();
          },
          (error) => {
            this.loading = false;
            this.processing = false;
            this.error = error.response.data.message;
          }
        );
      }
    },

    removeImage(key) {
      this.document.splice(key, 1);
    },
    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },

    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },

    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 30) {
              this.document.push(response.data.data.original);
            } else {
              this.x.error = "Maximum 30 proofs can be uploaded!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
  },
};
</script>
<style lang="scss">
.heading__span {
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: bold;
}
.active {
  background-color: darkorange !important;
}
.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
.fonttime2.vue__time-picker input.display-time {
  font-size: 12px;
}
.fonttime .dropdown.drop-down {
  top: -14%;
}
div ::-webkit-scrollbar-thumb {
  background: darkorange;
}
.labelheading {
  color: grey;
}
</style>

<style scoped>
.advance-ass-btn {
  text-decoration: none;
  color: white;
  border: 2px solid blue;
  background-color: blue;
  padding: 4px 12px;
  border-radius: 5px;
}
.pay-not-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 4px 12px;
  border-radius: 5px;
}
.title-layout-size {
  padding-top: 0px !important;
  padding-top: 0px !important;
}
.list {
  display: inline-block;
  position: relative;
}
.list {
  display: block;
}
.list {
  text-align: center;
  padding-left: 0px !important;
  display: inline !important;
}
.test1 {
  position: absolute;
  fill: rgba(0, 0, 0, 0.54);
  left: 5px;
  top: 20px;
  margin: 0 !important;
}
</style>
